import About from "./Components/About"
import Footer from "./Components/Footer"
import Hero from "./Components/Hero"
import Navbar from "./Components/Navbar"
// import Sponsor from "./Components/Sponsor"

const FestivalHome = () =>{
    return(
        <>
            <Navbar/>
            <Hero/>
            <About/>
            <Footer/>
        </>
        
       
    )
}

export default FestivalHome;